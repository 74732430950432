// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-datepicker-range {
  display: flex;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/js/components/Form/components/DatepickerRange/index.sass"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,sBAAA;AAAF","sourcesContent":["\n.form-datepicker-range\n  display: flex\n  flex-direction: column"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
