import { useEffect } from 'react';

import Address from '../../../Address';
import Icon from 'js/components/Icon';

import { listBody } from 'js/components/CollapseV2/variants/listBody';
import t from 'js/utils/translate';
import { getConcerns } from 'js/scenes/Freight/scenes/Concerns/store/getConcernsReducer';
import { content } from '../../../../utils';
import { findConcernByShopId } from 'js/utils/find';

import './index.sass';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

const ShopInfoBody = ({ shop }) => {
  const dispatch = useAppDispatch();

  const concerns = useAppSelector((state) => state?.freight?.concerns?.getConcerns?.data);
  const concernsLoaded = useAppSelector((state) => state.freight?.concerns?.getConcerns?.isLoaded);

  const translateKeyPrefix = 'shop.accordion.shopInfo.header.';
  const className = 'shop-info-body';

  useEffect(() => {
    if (!concernsLoaded) dispatch(getConcerns());
  }, []);

  const concern = findConcernByShopId(concerns, shop?.shopId) ?? false;

  return listBody(
    [
      {
        label: 'concern',
        content: concernsLoaded ? (
          content(concern?.name)
        ) : (
          <Icon icon='spin' className='fa-spinner' />
        ),
        orientation: 'row'
      },
      { label: 'shopId', content: content(shop?.shopId), orientation: 'row' },
      {
        label: 'organizationNumber',
        content: content(shop?.organizationNumber),
        orientation: 'row'
      },
      {
        label: 'legalCompanyName',
        content: content(shop?.legalCompanyName),
        orientation: 'row'
      },
      {
        label: 'delivererEDI',
        content: content(shop?.delivererEDI),
        orientation: 'row'
      },
      {
        label: 'paysVAT',
        content: shop?.paysVAT ? t('general.answer.yes') : (t('general.answer.no') ?? '-'),
        orientation: 'row'
      },
      {
        label: 'platform',
        content: content(shop?.platform),
        orientation: 'row'
      },
      {
        label: 'address',
        content: <Address data={shop} accessorPrefix='warehouse' />,
        orientation: 'row'
      },
      { label: 'ceo', content: content(shop?.ceo), orientation: 'row' },
      {
        label: 'contactPersonEmail',
        content: content(shop?.contactPersonEmail),
        orientation: 'row'
      },
      {
        label: 'contactPersonPhone',
        content: content(shop?.contactPersonPhone),
        orientation: 'row'
      },
      {
        label: 'customerHandler',
        content: content(shop?.customerHandler),
        orientation: 'row'
      }
    ],
    translateKeyPrefix,
    className
  );
};

export default ShopInfoBody;
