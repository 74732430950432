import AuthService from '@di/auth-service';

const auth = new AuthService({
  appName: 'flow',
  environment:
    process.env.APP_EXECUTION_ENV === 'local'
      ? 'local'
      : process.env.APP_ENV === 'stg'
        ? 'staging'
        : process.env.APP_ENV === 'prod'
          ? 'production'
          : 'development',
  usePkce: process.env.APP_EXECUTION_ENV === 'local'
});

export default auth;
