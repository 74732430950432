// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found-page {
  text-align: center;
}
.not-found-page svg {
  margin: 0 auto;
  width: auto;
  height: auto;
}
.not-found-page__content {
  margin-top: auto;
  margin-bottom: auto;
}
.not-found-page__content .button-wrapper {
  display: flex;
  justify-content: center;
}
.not-found-page__content .button-wrapper > div {
  padding-right: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/js/components/NotFoundPage/index.sass"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAAE;EACE,cAAA;EACA,WAAA;EACA,YAAA;AAEJ;AADE;EACE,gBAAA;EACA,mBAAA;AAGJ;AAFI;EACE,aAAA;EACA,uBAAA;AAIN;AAHM;EACE,mBAAA;AAKR","sourcesContent":[".not-found-page\n  text-align: center\n  svg\n    margin: 0 auto\n    width: auto\n    height: auto\n  &__content\n    margin-top: auto\n    margin-bottom: auto\n    .button-wrapper\n      display: flex\n      justify-content: center\n      > div\n        padding-right: 1rem\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
