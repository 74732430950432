// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.load-carriers-mobile-table .labeled-item {
  display: flex;
  align-items: baseline;
}
.load-carriers-mobile-table .labeled-item .label-wrapper {
  min-width: 100px;
}`, "",{"version":3,"sources":["webpack://./src/js/scenes/Home/scenes/OrderDetails/components/OrderInfo/components/OrderAccordion/components/LoadCarrierBody/index.sass"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,qBAAA;AAAJ;AACI;EACE,gBAAA;AACN","sourcesContent":[".load-carriers-mobile-table\n  .labeled-item\n    display: flex\n    align-items: baseline\n    .label-wrapper\n      min-width: 100px\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
