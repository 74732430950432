// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.submit-icon {
  background: none;
  padding: 0px;
  border: none;
}
.submit-icon .icon {
  padding-top: 25px;
}`, "",{"version":3,"sources":["webpack://./src/js/scenes/Settings/components/SystemMessages/components/SystemMessagesCollapse/components/SystemMessage/components/SystemMessageIcons/index.sass"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,YAAA;EACA,YAAA;AACF;AAAE;EACE,iBAAA;AAEJ","sourcesContent":[".submit-icon\n  background: none\n  padding: 0px\n  border: none\n  .icon\n    padding-top: 25px"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
