// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.environment-ribbon {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100000;
}`, "",{"version":3,"sources":["webpack://./src/js/components/AppHeader/components/EnvironmentRibbon/index.sass"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,eAAA;AACF","sourcesContent":[".environment-ribbon\n  position: absolute\n  top: 0\n  left: 0\n  z-index: 100000"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
