// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delivery__delivery-image {
  display: flex;
  flex: 1 1 100%;
}
.delivery__delivery-image img:not(:first-of-type) {
  margin-left: 1em;
}`, "",{"version":3,"sources":["webpack://./src/js/scenes/Home/scenes/OrderDetails/components/OrderInfo/components/OrderAccordion/components/DeliveryBody/index.sass"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,cAAA;AAAF;AACE;EACE,gBAAA;AACJ","sourcesContent":["\n.delivery__delivery-image\n  display: flex\n  flex: 1 1 100%\n  img:not(:first-of-type)\n    margin-left: 1em"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
