// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon, i, .fa {
  z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/js/components/Icon/index.sass"],"names":[],"mappings":"AAAA;EACI,UAAA;AACJ","sourcesContent":[".icon, i, .fa\n    z-index: 1\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
