// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.remove-shop-page {
  padding-top: 1rem;
}
.remove-shop-page__buttons {
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/js/scenes/Freight/scenes/Shops/scenes/Shop/scenes/RemoveShop/index.sass"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AAAE;EACE,aAAA;AAEJ","sourcesContent":[".remove-shop-page\n  padding-top: 1rem\n  &__buttons\n    display: flex\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
