// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-recipient {
  width: 100%;
  padding-right: 4em;
}
.edit-recipient > .labeled-input__container {
  padding-bottom: 1em;
}`, "",{"version":3,"sources":["webpack://./src/js/scenes/Home/scenes/OrderDetails/scenes/EditOrderParty/index.sass"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,kBAAA;AACF;AAAE;EACE,mBAAA;AAEJ","sourcesContent":[".edit-recipient\n  width: 100%\n  padding-right: 4em\n  > .labeled-input__container\n    padding-bottom: 1em\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
