import { ApiServiceSupplier, ApiFlowShopSimple } from 'src/interfaces/interfaces.generated';

export const filterServiceSuppliers = (
  serviceSuppliers: ApiServiceSupplier[],
  shops: ApiFlowShopSimple[],
  search: string
): ApiServiceSupplier[] =>
  serviceSuppliers?.filter((serviceSupplier) =>
    serviceSupplier?.shopAndExternalId?.some((sne) =>
      shops?.find((shop) =>
        shop?.shopId === sne?.shopId && shop?.name?.toLowerCase().includes(search)
          ? serviceSupplier
          : serviceSupplier?.id?.toString().includes(search) ||
            serviceSupplier?.name?.toLowerCase().includes(search)
      )
    )
  );

interface Option {
  value: number | undefined;
  label: string | undefined;
}

export const findSelectedServiceSupplierShops = (
  serviceSupplier: ApiServiceSupplier,
  shops: ApiFlowShopSimple[]
): Option[] | undefined =>
  serviceSupplier?.shopAndExternalId
    ?.map(
      (serviceSupplierShop) => shops.filter((shop) => serviceSupplierShop.shopId === shop.shopId)[0]
    )
    .map((filteredShops) => ({
      value: filteredShops.shopId,
      label: filteredShops.name
    }));
