// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datetime-wrapper {
  display: flex;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/js/scenes/Orders/scenes/LabelHistory/components/LabelHistoryRow/components/LabelHistoryInfo/components/ExcerptStats/components/LabelHistoryDateTime/index.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF","sourcesContent":[".datetime-wrapper\n  display: flex\n  flex-direction: column\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
