// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-data-description {
  display: flex;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/js/scenes/Home/components/MainPanel/components/PackageDashboard/components/DashboardData/components/DashboardDataDescription/index.sass"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;AADF","sourcesContent":["\n\n.dashboard-data-description\n  display: flex\n  flex-direction: column"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
