// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner {
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.spinner--page-spinner {
  margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/js/components/Spinner/index.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;AAAE;EACE,YAAA;AAEJ","sourcesContent":[".spinner\n  display: flex\n  justify-content: center\n  flex-direction: row\n  &--page-spinner\n    margin: auto\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
