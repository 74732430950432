// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shipment-info {
  display: flex;
  flex-direction: column;
}
.shipment-info__container {
  display: flex;
}
.shipment-info__container--link {
  text-decoration: underline;
}

.download-labels {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/js/scenes/Home/scenes/OrderSearch/components/OrderSearchResult/components/OrderSearchResultTable/components/ShipmentInfo/index.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AAAE;EACE,aAAA;AAEJ;AADI;EACE,0BAAA;AAGN;;AAFA;EACE,eAAA;AAKF","sourcesContent":[".shipment-info\n  display: flex\n  flex-direction: column\n  &__container\n    display: flex\n    &--link\n      text-decoration: underline\n.download-labels\n  cursor: pointer\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
