// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found {
  text-align: center;
  width: 100%;
}
.not-found svg {
  margin: 0 auto;
  width: auto;
  height: auto;
}`, "",{"version":3,"sources":["webpack://./src/js/components/NotFound/index.sass"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;AACF;AAAE;EACE,cAAA;EACA,WAAA;EACA,YAAA;AAEJ","sourcesContent":[".not-found\n  text-align: center\n  width: 100%\n  svg\n    margin: 0 auto\n    width: auto\n    height: auto\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
