export interface AddressCheckRequest {
  shopId: number;
  transportSolutionId: number;
  serviceSupplierId?: number;
  streetName?: string | number;
  streetNumber?: string | number;
  entrance?: string | number;
  floorType?: string | number;
  floor?: string | number;
  apartmentNumber?: string | number;
  countryCode?: string | number;
  zip?: string | number;
  city?: string | number;
  weightGrams?: number;
  externalId?: number;
}

export const buildAddressCheckRequest = (
  { shopId, transportSolutionId, serviceSupplierId, ...value },
  part
): AddressCheckRequest => ({
  shopId,
  transportSolutionId,
  serviceSupplierId,
  streetName: value[part]?.address?.streetName,
  streetNumber: value[part]?.address?.streetNumber,
  entrance: value[part]?.address?.entrance,
  floorType: value[part]?.address?.floorType,
  floor: value[part]?.address?.floor,
  apartmentNumber: value[part]?.address?.apartmentNumber,
  countryCode: value[part]?.address?.countryCode,
  zip: value[part]?.address?.zip,
  city: value[part]?.address?.city,
  weightGrams: 0,
  externalId: value[part]?.id
});

export const findFreightProductsInSelectedTransportSolution = (
  shopTransportSolutions,
  selectedTransportSolution,
  freightProducts
) => {
  const freightProductIdsInSelectedtransportSolution = () =>
    shopTransportSolutions?.find(
      ({ transportSolutionId }) => selectedTransportSolution === transportSolutionId
    )?.freightProductIds;

  return freightProducts?.filter((freightProduct) =>
    freightProductIdsInSelectedtransportSolution()?.find(
      (freightProductId) => freightProductId === freightProduct.freightProductId
    )
  );
};

export const freightProductsArePickup = (freightProducts) =>
  !!freightProducts?.find((freightProduct) => freightProduct?.pickupProduct);
