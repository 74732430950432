import { FC } from 'react';
import { RootState } from 'js/store/reducer';

import Icon from 'js/components/Icon';

import { ApiOrderSimple, ApiServiceSupplier } from 'src/interfaces/interfaces.generated';
import { findServiceSupplierById } from 'js/utils/find';
import { useAppSelector } from 'js/utils/hooks';
import Label from 'js/components/Label';
import TuLabeledItem from 'js/components/TuLabeledItem';
import { tu } from 'js/utils/translate';

interface Props {
  order: ApiOrderSimple;
}

const ParcelBody: FC<Props> = ({ order }) => {
  const serviceSuppliersLoaded: boolean = useAppSelector(
    (state: RootState) => state?.freight?.serviceSuppliers?.serviceSuppliers?.isLoaded
  );
  const serviceSuppliers: ApiServiceSupplier[] = useAppSelector(
    (state: RootState) => state?.freight?.serviceSuppliers?.serviceSuppliers?.data
  );

  const serviceSupplier: ApiServiceSupplier | undefined = findServiceSupplierById(
    serviceSuppliers,
    order.serviceSupplierId
  );

  const translationPrefix = 'orderDetails.accordion.parcel.';

  return (
    <div className='container'>
      <div className='row'>
        <TuLabeledItem
          className='col'
          label='trackingReference'
          translationKeyPrefix={translationPrefix}
        >
          <span>
            {' '}
            <Icon icon='tags' /> {order?.trackingReference ?? '-'}{' '}
          </span>
        </TuLabeledItem>

        <TuLabeledItem className='col' label='pickupCode' translationKeyPrefix={translationPrefix}>
          {order?.pickup?.pickupCode ?? '-'}
        </TuLabeledItem>

        <TuLabeledItem
          className='col'
          label='labelReference'
          translationKeyPrefix={translationPrefix}
        >
          {order?.labelReference ?? '-'}
        </TuLabeledItem>

        <TuLabeledItem className='col' label='value' translationKeyPrefix={translationPrefix}>
          {order?.contentValue ?? '-'} kr
        </TuLabeledItem>

        <TuLabeledItem className='col' label='supplier' translationKeyPrefix={translationPrefix}>
          {serviceSuppliersLoaded ? (
            (serviceSupplier?.name ?? '-')
          ) : (
            <Icon icon='spin' className='fa-spinner' />
          )}
        </TuLabeledItem>
      </div>

      <div className='row'>
        <TuLabeledItem className='col' label='content' translationKeyPrefix={translationPrefix}>
          {order?.description ?? '-'}
        </TuLabeledItem>

        <TuLabeledItem
          className='col'
          label='externalIdentifier'
          translationKeyPrefix={translationPrefix}
        >
          {order?.externalOrderId ?? '-'}
        </TuLabeledItem>

        <TuLabeledItem className='col' label='volume' translationKeyPrefix={translationPrefix}>
          {order?.volume ?? '-'}
        </TuLabeledItem>

        <div className='col spacer' />
        <div className='col spacer' />
      </div>

      <div className='row'>
        <Label label={tu(`${translationPrefix}measurements.original`)} />
      </div>

      <div className='row no-gap-top'>
        <TuLabeledItem className='col' label='weight' translationKeyPrefix={translationPrefix}>
          {order?.weight ?? '-'} g
        </TuLabeledItem>

        <TuLabeledItem className='col' label='length' translationKeyPrefix={translationPrefix}>
          {order?.lengthCM ?? '-'} cm
        </TuLabeledItem>

        <TuLabeledItem className='col' label='height' translationKeyPrefix={translationPrefix}>
          {order?.heightCM ?? '-'} cm
        </TuLabeledItem>

        <TuLabeledItem className='col' label='width' translationKeyPrefix={translationPrefix}>
          {order?.widthCM ?? '-'} cm
        </TuLabeledItem>

        <div className='col spacer' />
      </div>

      {(!!order?.updatedWeight ||
        !!order?.updatedLengthCM ||
        !!order?.updatedHeightCM ||
        !!order?.updatedWidthCM) && (
        <>
          <div className='row'>
            <Label label={tu(`${translationPrefix}measurements.updated`)} />
          </div>

          <div className='row no-gap-top'>
            <TuLabeledItem className='col' label='weight' translationKeyPrefix={translationPrefix}>
              {order?.updatedWeight ?? '-'} g
            </TuLabeledItem>

            <TuLabeledItem className='col' label='length' translationKeyPrefix={translationPrefix}>
              {order?.updatedLengthCM ?? '-'} cm
            </TuLabeledItem>

            <TuLabeledItem className='col' label='height' translationKeyPrefix={translationPrefix}>
              {order?.updatedHeightCM ?? '-'} cm
            </TuLabeledItem>

            <TuLabeledItem className='col' label='width' translationKeyPrefix={translationPrefix}>
              {order?.updatedWidthCM ?? '-'} cm
            </TuLabeledItem>

            <div className='col spacer' />
          </div>
        </>
      )}
    </div>
  );
};

export default ParcelBody;
