// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-order {
  width: 100%;
  padding-right: 4em;
}
.edit-order .message-for-user {
  margin-bottom: 2em;
}
.edit-order .labeled-toggle-group {
  flex-direction: column;
}
.edit-order__right-container {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/js/scenes/Home/scenes/OrderDetails/scenes/EditOrder/index.sass"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,kBAAA;AACF;AAAE;EACE,kBAAA;AAEJ;AADE;EACE,sBAAA;AAGJ;AADE;EACE,WAAA;AAGJ","sourcesContent":[".edit-order\n  width: 100%\n  padding-right: 4em\n  .message-for-user\n    margin-bottom: 2em\n  .labeled-toggle-group\n    flex-direction: column\n\n  &__right-container\n    width: 100%\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
