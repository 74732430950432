// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-status {
  width: 100%;
}
.edit-status__select-status {
  margin-top: 1em;
}`, "",{"version":3,"sources":["webpack://./src/js/scenes/Home/scenes/OrderDetails/scenes/EditStatus/index.sass"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AAAE;EACE,eAAA;AAEJ","sourcesContent":[".edit-status\n  width: 100%\n  &__select-status\n    margin-top: 1em\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
