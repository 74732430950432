import { FC, useEffect, useMemo } from 'react';
// eslint-disable-next-line max-len
import { getCustomerCommunication } from 'js/scenes/Freight/scenes/Shops/scenes/Shop/store/customerCommunicationReducer';
import { RootState } from 'js/store/reducer';
import TableV2 from 'js/components/TableV2';
import t, { tu } from 'js/utils/translate';
import Icon from 'js/components/Icon';
import Tags from 'js/scenes/Freight/scenes/Shops/scenes/Shop/components/ShopAccordion/components/Tags';
import './index.sass';
import { ApiCustomerMessage, ApiFlowShop } from 'src/interfaces/interfaces.generated';
import Label from 'js/components/Label';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';

interface CustomerCommunicationBodyProps {
  shop: ApiFlowShop;
}

const CustomerCommunicationBody: FC<CustomerCommunicationBodyProps> = ({ shop }) => {
  const dispatch = useAppDispatch();

  const allFreightProducts = useAppSelector((state: RootState) => state.freightProducts.data);
  const customerCommunication = useAppSelector(
    (state: RootState) => state.freight.shop.customerCommunication.data
  );

  useEffect(() => {
    dispatch(getCustomerCommunication(shop.shopId));
  }, [shop.shopId]);

  const columns = useMemo(
    () => [
      {
        Header: t('customer.communication.templateKey'),
        accessor: (customerMessage: ApiCustomerMessage) =>
          t('customer.communication.customer.message.' + customerMessage.customerMessageId),
        inHeader: true
      },
      {
        Header: t('customer.communication.channel'),
        accessor: 'channel'
      },
      {
        Header: t('general.active'),
        accessor: ({ active }) => (active ? <Icon icon='check' /> : t('general.answer.no'))
      },
      {
        Header: t('customer.communication.freightProducts'),
        accessor: ({ freightProducts }) => (
          <Tags
            list={allFreightProducts
              .filter((freightProduct) =>
                freightProducts?.includes(Number(freightProduct.freightProductId))
              )
              .map((fp) => fp.name)}
          />
        )
      },
      {
        Header: t('customer.communication.additionalText'),
        accessor: 'additionalText'
      }
    ],
    []
  );

  return (
    <>
      <div className='customer-communication-body'>
        <Label label={tu('customer.communication.customer.facing.name')} />
        <span>{customerCommunication?.customerFriendlyName}</span>
      </div>
      <TableV2
        columns={columns}
        data={customerCommunication.customerMessages}
        mobileTableVariant='withSmallEditIcon'
        className='customer-communication-body'
      />
    </>
  );
};

export default CustomerCommunicationBody;
